.customer-badge,
.product-badge,
.order-badge {
    border-radius: var(--border-radius);
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.customer-badge {
    &.status-default {
        background: #C8E6C9;
        color: #256029;
    }
    &.status-qualified {
        background: #C8E6C9;
        color: #256029;
    }

    &.status-unqualified {
        background: #FFCDD2;
        color: #C63737;
    }

    &.status-negotiation {
        background: #FEEDAF;
        color: #8A5340;
    }

    &.status-new {
        background: #B3E5FC;
        color: #23547B;
    }

    &.status-renewal {
        background: #ECCFFF;
        color: #694382;
    }

    &.status-proposal {
        background: #FFD8B2;
        color: #805B36;
    }

    &.status-green {
        background: #13df95;
        color: #333;
    }
    &.status-lila {
        background: #d37eff;
        color: #333;
    }
    &.status-black {
        background: #000000;
        color: #fff;
    }
    &.status-grey {
        background: #afafaf;
        color: #fff;
    }
    &.status-lightRed {
        background: #ff7676;
        color: #fff;
    }
    &.status-blue {
        background: #1363df;
        color: #fff;
    }
    &.status-lightBlue {
        background: #80b2ff;
        color: #333;
    }
    &.status-yellow {
        background: #efc108;
        color: #333;
    }
    &.status-darkGreen {
        background: #00814c;
        color: #fff;
    }
}

.product-badge {
    border-radius: var(--border-radius);
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;

    &.status-default {
        background: #C8E6C9;
        color: #256029;
    }
    &.status-instock {
        background: #C8E6C9;
        color: #256029;
    }

    &.status-outofstock {
        background: #FFCDD2;
        color: #C63737;
    }

    &.status-lowstock {
        background: #FEEDAF;
        color: #8A5340;
    }

    &.status-green {
        background: #13df95;
        color: #333;
    }
    &.status-lila {
        background: #d37eff;
        color: #333;
    }
    &.status-black {
        background: #000000;
        color: #fff;
    }
    &.status-grey {
        background: #afafaf;
        color: #fff;
    }
    &.status-lightRed {
        background: #ff7676;
        color: #fff;
    }
    &.status-blue {
        background: #1363df;
        color: #fff;
    }
    &.status-lightBlue {
        background: #80b2ff;
        color: #333;
    }
    &.status-yellow {
        background: #efc108;
        color: #333;
    }
    &.status-darkGreen {
        background: #00814c;
        color: #fff;
    }
}

.order-badge {
    border-radius: var(--border-radius);
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;

    &.order-delivered {
        background: #C8E6C9;
        color: #256029;
    }

    &.order-cancelled {
        background: #FFCDD2;
        color: #C63737;
    }

    &.order-pending {
        background: #FEEDAF;
        color: #8A5340;
    }

    &.order-returned {
        background: #ECCFFF;
        color: #694382;
    }
}
